/* @font-face {
  font-family: "Lato", sans-serif;
  src: url(https://fonts.googleapis.com/css?family=Lato:400,900&display=swap);
  font-display: swap;
} */
body {
  font-family: "Lato", sans-serif;
  color: black;
  overflow-x: hidden !important;
  font-display: swap;
}
.bnr {
  background-image: url("./assets/expobird-banner-image.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}
.bnr-video {
  background-image: url("./assets/expobird-banner-image.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 610px;
}
.play {
  font-size: 1rem !important;
}
@media (max-width: 901px) {
  .menu.navbar .nav {
    display: none;
  }
}

@media (min-width: 1000px) {
  .menu.navbar .nav {
    display: flex;
  }
}
.navbar {
  background: #2e3192;
  color: white;
  width: 100%;
  justify-content: space-evenly;
  transition: all ease-in 0.2s;
}
.App .navbar {
  justify-content: space-evenly !important;
  z-index: 3 !important;
}
.menu a {
  color: white;
  font-weight: bold;
}
.contact {
  font-weight: bold;
}
#banner-img {
  width: 100%;
}
#logo {
  width: 76px;
}
.call-icon {
  width: 16px;
}
/* .menu #logo {
  margin-left: 6rem;
} */
.BannerP {
  color: #fff;
  font-weight: 600;
}
.heading-text #heading-main {
  font-weight: 900;
  font-size: 2.2rem !important;
  color: white;
}
.Webdev-Text {
  font-size: 1.5rem;
  font-weight: 400;
}
.heading-text p {
  font-size: 1.2rem;
  color: white;
}
.main-video {
  border-radius: 22px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.51) 0px 9px 16px 0px;
  margin-left: 5rem;
}

h2 {
  color: white;
  font-weight: bold;
}

.rf {
  border-radius: 50px;
  appearance: none;
  outline: none;
  background: linear-gradient(
    to bottom,
    #ffa330,
    #ff9346,
    #ff8559,
    #fb7a6a,
    #f07379
  );
  color: #fff;
  border: none;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
}
.rf:focus {
  outline: none;
}
.rf:hover {
  box-shadow: 0 3px 16px 0 rgba(243, 124, 108, 0.5);
  transition: ease-in-out 0.2s;
}
#sub-text {
  font-size: 1.3rem;
  opacity: 0.6;
  font-weight: 600;
}
#menu-icon {
  font-size: 32px;
  font-weight: 900;
}
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 36px;
}
#main {
  transition: margin-right 0.5s;
}
.nostyling {
  background: none;
  appearance: none;
  outline: none;
  border: none;
  color: white;
}

.nav a:hover {
  color: #ff9346 !important;
  transition: 0.2s ease-in;
}

.heading-page {
  color: #2e3192;
  font-weight: 900;
  font-size: 3.5rem;
  margin-top: 20px;
}
#clients-img {
  text-align: center;
  width: inherit;
}

.explore-services {
  color: #000000 !important;
  font-weight: 600 !important;
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
}
.explore-services:hover .fa-arrow-alt-circle-right {
  margin-left: 3px;
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.2s;
  text-decoration: none;
  cursor: pointer;
}
.bg-white {
  display: block;
  background-color: white;
  border-radius: 10px;
  /* color: black; */
  padding: 1pc;
  box-shadow: 0 3px 16px 0 #d2d5d8;
  /* margin-left: 1rem !important; */
  /* margin-right: 1.5rem !important; */
}

.if {
  display: inline-flex;
}
.services-heading {
  color: #2e3192;
  font-weight: 600;
  font-size: 1.3rem;
}
.services-bg {
  background: url("./assets/services bg.webp") no-repeat fixed;
  background-size: cover;
  width: 100%;
  height: auto;
}
.services {
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  border-radius: 50px;
  appearance: none;
  outline: none;
  background: linear-gradient(
    to bottom,
    #ffa330,
    #ff9346,
    #ff8559,
    #fb7a6a,
    #f07379
  );
  color: #fff;
  border: none;
}
.services:focus {
  outline: none;
}
.services:hover {
  box-shadow: 0 3px 16px 0 rgba(243, 124, 108, 0.5);
  transition: ease-in-out 0.2s;
}
.services-cta {
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  border-radius: 50px;
  appearance: none;
  -moz-appearance: none;
  outline: none;
  /* background: #2e3192; */
  background-image: linear-gradient(to left, #2e3192, #1319ce);
  color: #fff;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: 0 3px 16px 0 rgba(2, 28, 99, 0.438);
}
.services-cta:active,
.services-cta:focus {
  outline: none;
}
.services-cta:hover {
  /* opacity: 0.9; */
  box-shadow: none;
  transition: ease-in-out 0.2s;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.dropdown-menu-headings {
  margin: 0;
  font-size: 18px;
}
p.dropdown-menu-text {
  color: #6d7c90 !important;
}
.sub-menu-button {
  width: 100%;
  background-color: #3776ff;
  padding: 0.5rem;
  border-radius: 20px;
  border: none;
  color: white;
}
.sub-menu-video {
  width: 56px;
  height: 56px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #2e3192;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  background-color: #2e3192;
  color: #f1f1f1;
}
.dropdown-container {
  display: none;
  background-color: #f6f6f6;
  color: #2e3192;
  font-size: 1rem;
}
.dropdown-container a:hover {
  background-color: #ffffff;
  color: #2e3192;
}
.dropdown a:link {
  text-decoration: none;
  color: white;
}

.dropdown-content {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 900px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-12rem) translateY(0px) translateZ(-3em);
  z-index: -1;
}

.dropdown-show {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  transform: translateX(10px);
}

.dropdown:focus .dropdown-content,
.dropdown:focus-within .dropdown-content,
.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.FooterColor {
  color: #ffffff;
  line-height: 2rem;
  font-size: 1.1rem;
}
.FooterColor:hover {
  color: #fff;
  text-decoration: none;
}
.footerText {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
}
.footer {
  background-color: #2e3192;
  color: white;
}
.footerBorder {
  border-bottom: solid 1px white;
}
.footerEnd {
  background-color: #2a2168;
  background-color: #252877;
}
.email-panel {
  background-color: #f6f6ff79;
  border-radius: 3rem;
}
.chimney::after {
  content: "";
  display: block;
  height: 6px;
  width: 40px;
  position: relative;
  left: 49%;
  margin-top: 15px;
  text-align: center;
  background-color: #ff8559;
}
.email-field {
  border-radius: 50px;
  padding: 15px;
  border: none;
  outline: none;
  height: 2.5rem;
  outline: none;
}
#name-submit {
  width: 14rem;
}
#submit-btn {
  margin-left: -3rem;
}
.list-none {
  list-style-type: none;
}
.ft-menu {
  text-align: left;
}
.list-none li {
  padding: 0.7rem;
  color: lightgrey;
}
.footer-menu-heading {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: white !important;
}
.footerLogo {
  color: #ffffff;
}
.footer-Mainlogo {
  width: 80px;
}
.heart {
  color: red;
}
acronym {
  text-decoration: none;
}
.slight-br {
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.layout-heading {
  font-size: 1.8em;
  letter-spacing: 1px;
  color: #2e3192;
  font-weight: 600;
}
.col-4 video {
  width: 292px;
  height: 214px;
  text-align: center;
}
.accordion {
  background-color: white !important;

  color: black !important;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none !important;
  border-bottom: 1px solid lightgray !important;
  text-align: left !important;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  text-transform: capitalize;
}
.accordion:focus,
.accordion:active {
  -webkit-appearance: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.fa-plus {
  font-size: 12px;
  float: right;
  margin-top: 4px;
}
.cf-fields {
  background: rgba(109, 124, 144, 0.1);
  padding: 0.5rem;
  border-radius: 7px;
  width: 20rem;
  text-decoration: none;
  outline: none;
  border: 0px;
  box-shadow: 0px 4px 5px 0px #2e319270;
  transition: ease-in-out;
}
.cf-fields:focus {
  box-shadow: none;
  text-decoration: none;
  outline: none;
}
#ah2 {
  letter-spacing: 1px;
}
.cf-submit {
  width: 50%;
}
.cta-popup {
  background: -webkit-radial-gradient(
    top left,
    circle,
    #4a42ec 0%,
    #3a1567 100%
  );
  background: radial-gradient(circle at top left, #4a42ec 0%, #3a1567 100%);
  box-shadow: 0 48px 48px -32px rgba(23, 16, 159, 0.2),
    0 96px 96px -64px rgba(3, 2, 20, 0.6);
  border-radius: 5px;
  letter-spacing: 1px;
  z-index: 5;
}

.pop-btn {
  background: white;
  color: #ff3190;
  /* width: 10rem; */
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: none;
}
.pop-modal {
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: -webkit-radial-gradient(
    top left,
    circle,
    #4a42ec 0%,
    #3a1567 100%
  );
  background: radial-gradient(circle at top left, #4a42ec 0%, #3a1567 100%);
  opacity: 1;
}
#myModal {
  transition: ease-in-out;
}
#popup {
  position: relative;
  z-index: 4;
}

/* CaseStudies Frutaz */
.max-h-800px {
  max-height: 800px;
}

.bg-F8B123 {
  background-color: #f8b123;
}

.bg-F9F9F9 {
  background-color: #F9F9F9;
}

.bg-FF5C46 {
  background-color: #FF5C46;
}

.bg-FF3023 {
  background-color: #FF3023;
}

.bg-CF1C25{
  background-color: #CF1C25;

}

.bg-000000{
  background-color: #000000;
}

.wavy-bg {
  --mask: radial-gradient(67.08px at 50% 90px, #000 99%, #0000 101%)
      calc(50% - 60px) 0/120px 51% repeat-x,
    radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px
      calc(51% - 30px) repeat-x,
    radial-gradient(67.08px at 50% calc(100% - 90px), #000 99%, #0000 101%)
      calc(50% - 60px) 100%/120px 51% repeat-x,
    radial-gradient(67.08px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50%
      calc(100% - 30px) / 120px calc(51% - 30px) repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.gap-20 {
  gap: 5rem;
}

.gap-8 {
  gap: 2rem;
}

.leading-none {
  line-height: 1 !important;
}

.text-200px {
  font-size: 200px;
}

.text-FFDE9B {
  color: #ffde9b;
}

.text-A1A1A1 {
  color: #a1a1a1;
}

.text-151515 {
  color: #151515;
}

.text-FF3023{
  color: #FF3023;
}
.text-FFFFFF{
  color: #FFFFFF;
}

.gradient-text{
  background: linear-gradient(89.38deg, #FF5C46 -28.98%, #FF8038 41.96%, #FF3023 115.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.max-w-600px {
  max-width: 600px;
}

.max-w-480px {
  max-width: 480px;
}

.max-w-850px {
  max-width: 850px;
}

.max-h-500px {
  max-height: 500px;
}

.max-w-166px{
  max-width: 166px;
} 

.max-h-94px{
  max-height: 94px;
}

.list-disc {
  list-style: disc;
}

.bg-FEA31A{
  background-color: #FEA31A;
}

.bg-FFB800{
  background-color: #FFB800;
}

.bg-95BFFC{
  background-color: #95BFFC;
}

.bg-FFFFFF{
  background-color: #FFFFFF;
}

.h-fit{
  height: fit-content;
}

.rounded-30{
  border-radius: 30px;
}

.gap-16 {
  gap: 4rem;
}

.mt-48 {
  margin-top: 12rem;
}

.rounded-r-30{
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.text-ACACAC{
  color: #ACACAC;
}

/*  */

.anti-animo {
  position: relative;
  animation-name: example;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: reverse;
}
.et-highlightable {
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  z-index: 999;
}
.et-highlighted {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.et-highlighted-overlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(78, 47, 188, 0.8);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  z-index: 3;
}
.testi-card {
  /* height: 350px; */
  box-shadow: 0 2px 22px 8px rgba(56, 125, 255, 0.1);
  padding: 2rem;
  position: relative;
  border-radius: 10px;
}
.test-quotes {
  width: 80px;
  height: 52px;
  position: absolute;
  top: -15px;
}
.rst {
  transform: scaleY(-1);
  position: absolute;
  left: 0px;
  width: 150px;
  height: 52px;
  bottom: 0px;
}
.lst {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 125px;
  height: 52px;
}
.test-quotes2 {
  width: 80px;
  height: 52px;
  position: absolute;
  top: -15px;
  right: 0px;
}
.rst2 {
  position: absolute;
  left: 0px;
  top: -10px;
  width: 150px;
  height: 52px;
}
.lst2 {
  transform: scaleY(-1);
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 125px;
  height: 52px;
}
.testi-margin {
  margin-top: 8rem !important;
}
.testi-margin2 {
  margin-top: 5rem;
}
.testi-margin4 {
  margin-top: -3rem;
}
.blueC {
  position: absolute;
  bottom: -1rem;
  right: 10%;
  width: 55px;
  height: 52px;
}
.blueC2 {
  position: absolute;
  bottom: -1rem;
  left: 10%;
  width: 55px;
  height: 52px;
}
.bg-testi img {
  position: absolute;
  z-index: -1;
  /* bottom: -2rem; */
  transform: translateX(820px) translateY(-320px);
  /* right: 1pc; */
  width: 350px;
}
.bg-testi2 img {
  position: absolute;
  z-index: -1;
  transform: translateX(795px) translateY(-380px);
  /* top: -1.5rem; */
  /* right: 30%; */
  width: 35px;
}

#diviCover #dC {
  max-width: 732px;
  width: 694px;
  border-radius: 10px;
  z-index: 233;
  background: linear-gradient(120deg, #2e3390 0%, #513d8d 100%);
  margin-top: 25px;
  height: 373px;
}
.play-btn {
  position: absolute;
  height: 164px;
  width: 164px;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(
    330deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  box-shadow: 0 24px 72px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  top: 49%;
  right: 34%;
  background-size: 200% auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.play-btn-inside {
  position: relative;
  top: 20%;
  left: 20%;
  height: 100px;
  width: 100px;
  background: white;
  border-radius: 100px;
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
}

.play-btn :hover {
  transform: scale(0.9);
}
.play-btn-inside :hover {
  transform: scale(1);
}
.play-btn-video :hover {
  transform: scale(0.9);
}
.play-btn-video-inside :hover {
  transform: scale(1);
}
.fa-play {
  font-size: 1.5rem;
}
.circle img {
  position: absolute;
  /* top: -9%; */
  /* right: 13%; */
  transform: translateX(270px) translateY(-430px);
  z-index: -1;
  opacity: 0.3;
}
/* .image-container::after {
  margin-top: 25px;
  border-radius: 8px;
  content: "";
  width: 59.3% !important;
  height: 93.5%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 20.5% !important;
  background: linear-gradient(120deg, #2e3390 0%, #513d8d 100%);
  opacity: 0.5;
} */
.pt-6 {
  padding-top: 6rem;
}

.pentagon img {
  position: absolute;
  /* top: -18%; */
  /* left: -27%; */
  z-index: -1;
  opacity: 0.1 !important;
  transform: scale(-1, -1) translateX(725px) translateY(500px);
}
.pentagon-about {
  width: 600px;
  position: absolute;
  z-index: -1;
  opacity: 0.1 !important;
  top: 10%;
  left: 20%;
  transform: scale(-1, -1);
}
button {
  border: none;
}
.layout-btn {
  border-radius: 50px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #5c55ee;
  box-shadow: 0 12px 24px -6px rgba(74, 66, 236, 0.2);
  color: white;
}
.wave-image {
  background-image: linear-gradient(to top, #134596, #2e338a);
}
.seo-banner {
  background-image: linear-gradient(to top, #134596, #2e338a);

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#2e3192',GradientType=1 );
  height: max-content;
}
#seo-pentagon {
  position: absolute;
  top: 2%;
}
#seo-pentagon img {
  width: 250px;
}
#seo-form {
  margin-top: 2rem;
  background-color: white;
  border-radius: 8px;
  padding: 3rem 2rem 3rem 2rem;
}
#seo-form h2 {
  text-align: left !important;
  color: black;
}
#seo-form input {
  margin: 0.5rem;
  overflow: visible;
  height: 50px;
  border-radius: 8px;
  border: 1px solid lightgray;
  width: 100%;
  padding: 0.5rem;
}
.fw {
  font-weight: bold;
}
#seo-form ::placeholder {
  font-size: 14px;
}
#seo-pink {
  position: absolute;
  top: 0%;
  right: 0%;
}
#seo-pink img {
  width: 200px;
}
#seo-submit {
  background: linear-gradient(90deg, #453288, #4f8fda);
  color: white;
  width: 100px !important;
  height: 40px !important;
}
.skew-right {
  -webkit-transform: rotateY(-16deg) rotateX(8deg) skew(-10deg, 8deg) scale(1.1)
    translateX(-8%);
  transform: rotateY(-16deg) rotateX(8deg) skew(-10deg, 8deg) scale(1)
    translateX(-8%);
}
.pr5 {
  padding-right: 5rem !important;
}
.small-btn {
  font-size: 14px;
  border: none;
  border-radius: 50px;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
#btn-blue {
  background-color: #3776ff;
}
#btn-lb {
  background-color: #37a8ff;
}

#seo-cta-pink {
  position: absolute;
  top: 0%;
  right: 0%;
  left: 0%;
  transform: scaleX(-1);
  width: 125px;
}
.seo-cta {
  background-color: #4a42ec;
}
.seo-cta .wave-image {
  bottom: 12%;
}
.lets-work {
  margin-top: 8rem;
}
.white-btn {
  border: none;
  color: black;
  background-color: white;
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 4px;
}
.white-btn:hover {
  filter: none;
  transition: ease-in-out 0.2s;
}
.ch {
  height: 400px;
  background-color: #2e3192;
}
#textA {
  width: 93%;
}
.slight1-br {
  width: 96% !important;
  transform: rotateY(16deg) rotateX(8deg) skew(10deg, -8deg) scale(1.1)
    translateX(8%);
  margin-top: 48px !important;
  padding-left: -14px !important;
  padding-right: 19px !important;
}
.clientImage {
  object-fit: contain;
  filter: grayscale(100%);
  transition: 0.5s;
}
.clientImage2 {
  width: 100%;
}
.clientImage1 {
  width: 260px;
  filter: none !important;
}
.clientImage:hover {
  filter: none;
}
#whatsapp-icon {
  border-radius: 20px;
  width: 130px;
}

.homepageBackground {
  background-image: linear-gradient(to top, #564293, #392870);
  box-shadow: 0 48px 48px -32px rgba(23, 16, 159, 0.2),
    0 96px 96px -64px rgba(23, 16, 159, 0.4) !important;
  color: white !important;
}
#heading-main {
  font-weight: 900;
  font-size: 3.5rem !important;
  color: white;
}
.banner-one__video {
  width: 95px;
  height: 95px;

  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 27px solid #f0f2f4;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 43%;
  right: 41%;
  z-index: 10;
  background-size: 200% auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--thm-base)),
    color-stop(51%, var(--thm-primary)),
    to(var(--thm-base))
  );
  background-image: linear-gradient(
    90deg,
    var(--thm-base) 0%,
    var(--thm-primary) 51%,
    var(--thm-base) 100%
  );
  color: #f0f2f4;
  font-size: 40px;
}
.banner-one__video:hover {
  background-position: right;
}
.video-margin {
  margin-left: 54px;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.ctaWhatsappButton {
  width: 194px;
  height: 40px;
  object-fit: contain;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 16.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.41px;
  font-family: Lato;
  font-size: 16.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.41px;
  text-align: center;
  color: #fc40b4;
}
.whButtonClr {
  color: green;
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.callButtonclr {
  color: #29409a;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.cta-button {
  width: 160px;
  height: 42px;
  object-fit: contain;
  border-radius: 20.5px;
  background-color: #3777ff;
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  text-align: center;
  color: #ffffff;
}
.whatsapp-icon {
  position: fixed;
  left: 2%;
  bottom: 12%;
  z-index: 1;
}

.testimonial {
  /* margin-top: -145px; */
  transform: translateY(0px);
}
.cta-image-right img {
  width: 420px;
  height: 308px;
}
.heading-page-cta {
  color: #2e3192;
  font-weight: 900;
  margin-top: 20px;
  font-size: 3.5rem;
  text-align: center;
}

#email-submit {
  width: 16rem;
}

.card2 {
  margin-top: 0rem;
}

.play-btn-video {
  position: absolute;
  height: 164px;
  width: 164px;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(
    330deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  box-shadow: 0 24px 72px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  top: 40%;
  right: 15%;
  background-size: 200% auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.play-btn-video-inside {
  position: relative;
  top: 20%;
  left: 20%;
  height: 100px;
  width: 100px;
  background: white;
  border-radius: 100px;
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
}

.pr5 {
  padding-right: 5rem !important;
}
#btn-lb {
  background-color: #37a8ff;
}

.text-right {
  text-align: right !important;
}

.videoSize {
  width: 514px;
}
.whatsapp-icon img {
  width: 50px;
  height: 50px;
}

.margin-left5 {
  margin-left: 0rem !important;
}

.marginTop {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.marginBottom {
  margin-bottom: 2rem;
}
.mediaIcon {
  filter: brightness(0) invert(1);
  transition: icon 0.2s ease-in-out;
}
.footerIcons {
  filter: brightness(0) invert(1);
}

.case-study-backgroundColor {
  background-color: #2e3192;
  height: 600px;
}
.case-study-font {
  font-size: 8rem;
  color: white;
}

.contact_container {
  background-color: #2e3192;
}
.contact-header1 {
  font-size: 1.5rem;
  color: #ffffff;
  letter-spacing: 10px;
}

.contact-header2 {
  font-size: 4.5rem;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.3rem;
}

.header-description {
  font-size: 1.5rem;
  color: #000000be;
}

.Cards {
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0px 2px 70px 0px rgba(110, 130, 208, 0.18);
}
.Cards h5 {
  font-weight: 700;
  color: #2e3192;
}
.Cards p {
  line-height: 1.8em;
  color: #000000be;
}
.contactCard h2 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #2e3192;
}
.contactCard p {
  font-weight: 300;
  font-size: 1.2rem;
  color: #000000be;
}
.inputBox {
  line-height: 1.8em;
  background-color: #ffffff;
  color: #000000;
  font-size: 1rem;
  border-radius: 4px;
  border-style: solid !important;
  border-width: 0 0 3px 0 !important;
  border-color: #000000 !important;
  width: 100%;
}
.inputBox:focus {
  outline: none;
  border-width: 0px 0px 0px 0px;
}
#Text-Area {
  width: 66rem;
}
#textBox {
  line-height: 1.8em;
  background-color: #f9fafd;
  color: #000000be;
  font-size: 1rem;
  border-radius: 4px;
  border-width: 0px 0px 3px 0px;
  border-bottom-color: #bec2d8;
}
#textBox:focus {
  outline: none;
  border-width: 0px 0px 0px 0px;
}
.case-study {
  height: 500px;
  background-image: url("./assets/oxford-sweater.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.case-study-2 {
  height: 500px;
  background-image: url("./assets/diamond-homes.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.case-study-image {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: auto;
  width: auto;
  margin: auto;
}
.case-study h1 {
  font-size: 3rem;
  color: white;
  margin-top: 6rem;
  margin-bottom: 1rem;
}
.case-study p {
  color: white;
  width: 390px;
}
.case-study-2 h1 {
  font-size: 3rem;
  color: white;
  margin-top: 6rem;
  margin-bottom: 1rem;
}
.case-study-2 p {
  color: white;
  width: 390px;
}
.oxford-case-study {
  background-image: url("./assets/oxford-sweater.png");
  background-position: center;
  height: 600px;
}
.case-study-description h2 {
  margin-top: 2rem !important;
  max-width: 950px;
  margin: auto;
  color: #e88330;
  font-weight: 600;
  margin-bottom: 1rem !important;
}
.case-study-description p {
  font-style: normal;
  font-weight: 300;
  color: #848484;
  font-size: 1.125rem;
  max-width: 950px;
  margin: auto;
}
.AboutPageLeft {
  background-color: #eff4ff;
}
.about-heading {
  font-size: 3.5rem;
  font-weight: 700;
  color: #2e3192;
}
.about-Text {
  font-size: 1rem;
  line-height: 1.8em;
  color: black;
  font-weight: 400;
}
.about-Text span {
  margin-top: 5rem;
}
.AboutPageRight {
  background: no-repeat;
  background-image: url("./assets//image.svg");
}
.Founder-Header {
  font-size: 3.5rem;
  font-weight: 700;
  color: #2e3192;
}
.Founder-Text {
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
}
.Founder-Description {
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  line-height: 1.2em;
}
.case-study-result {
  font-size: 4rem;
}
.call-text {
  border-radius: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: linear-gradient(
    to bottom,
    #ffa330,
    #ff9346,
    #ff8559,
    #fb7a6a,
    #f07379
  );
  color: #fff;
  border: none;
  font-size: 15px;
}
.call-text:hover {
  box-shadow: 0 3px 16px 0 rgba(243, 124, 108, 0.5);
  transition: ease-in-out 0.2s;
}
.BlogTopHeader {
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 700;
  background: -webkit-linear-gradient(270deg, #3239ee 10%, #2e3192 100%);
  -webkit-text-fill-color: transparent;
}
.InputDiv {
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  position: relative;
}
.InputDiv > input {
  position: relative;
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  width: 100%;
  margin: 16px 0;
  padding: 8px 12px;
  text-align: left;
  border: 2px solid transparent;
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.9em;
  font-family: lato, sans-serif;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}
.SearchIcon {
  height: 28px;
  width: auto;
  right: 5%;
  z-index: 2;
  top: 24px;
  position: absolute;
}
.BlogTopText {
  font-size: 1.5rem;
  font-weight: 500;
  color: #6d7c90;
}
.BlogContent-bg {
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
    0 12px 90px 0 rgba(103, 151, 255, 0.11);
}
.Blog-Header {
  font-size: 4rem;
  font-weight: 700;
  background: -webkit-linear-gradient(100deg, #3239ee 40%, #2e3192 100%);
  -webkit-text-fill-color: transparent;
}
.BlogListHeading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #011624;
}
.BlogList {
  line-height: 2.5rem;
}
.BloglistFS {
  color: #6d7c90;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  line-height: normal;
  font-size: 1.2rem;
}
.BlogList :hover {
  color: #212529;
  border-bottom: none;
  text-decoration: none;
}
.BlogList :focus {
  text-decoration: none;
  border-bottom: none;
  color: #011624;
}
.SocialIcon1-bg {
  background-color: rgb(179, 20, 20);
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.SocialIcon2-bg {
  background-color: #00abf0;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.SocialIcon3-bg {
  background-color: #127bb6;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.SocialIcon4-bg {
  background: linear-gradient(270deg, #6660d6 20%, #da4277 100%);
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.SocialIcon1-bg :hover {
  transform: scale(0.9);
  transition: ease-in 0.3s;
  box-shadow: none;
}
.SocialIcon2-bg :hover {
  transform: scale(0.9);
  transition: ease-in 0.3s;
}
.SocialIcon3-bg :hover {
  transform: scale(0.9);
  transition: ease-in 0.3s;
}
.SocialIcon4-bg :hover {
  transform: scale(0.9);
  transition: ease-in-out 0.3s;
}
.Icon-bg {
  border-radius: 3rem;
  background-color: rgb(129, 15, 15);
}
.iconColor {
  color: #fff;
}
.SocialIcon-Text {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
.BlogPage-Header {
  font-size: 40px;
  font-weight: 800;
  color: #2e3192;
}
.blog-details {
  color: rgba(105, 102, 135);
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
}
.BlogDetailList::after {
  content: "|";
  color: rgba(105, 102, 135);
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.BlogPage-Image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
}
.BlogPage-Image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  transition: opacity 0.7s, -webkit-transform 0.8s;
  transition: transform 0.8s, opacity 0.7s;
  transition: transform 0.8s, opacity 0.7s, -webkit-transform 0.8s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  overflow: hidden;
  visibility: visible;
  display: block;
}
.BlogPage-Image img:hover {
  transition: ease-in-out 1s;
  transform: scale(1.2);
}
.NextBlog-Img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}

.NextBlog-Img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  transition: opacity 0.7s, -webkit-transform 0.8s;
  transition: transform 0.8s, opacity 0.7s;
  transition: transform 0.8s, opacity 0.7s, -webkit-transform 0.8s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  overflow: hidden;
  visibility: visible;
  display: block;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.NextBlog-Img img:hover {
  transition: ease-in-out 1s;
  transform: scale(1.2);
}
.NextBlog-Title {
  color: #3b3663;
  font-weight: 800;
  font-size: 1.5rem;
  text-decoration: none;
}
.NextBlog-Title:hover {
  color: #3239ee;
  text-decoration: none;
}
.NextBlog-bg {
  background-color: #f5f6f7;
  border-radius: 2rem;
}
.ReadmoreLink {
  text-decoration: none;
  font-size: 1rem;
  color: #2e3192;
}
.ReadmoreLink:hover {
  color: #ff8559;
  text-decoration: none;
}
.BlogPage-Image1 {
  max-width: 100%;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  visibility: visible;
  display: block;
}
.BlogPage-Text {
  font-size: 1.2rem;
  line-height: 2rem;
}
.BlogPage-Title {
  color: #3b3663;
  font-weight: 800;
  /* font-size: 1.5rem; */
  /* transform: translateY(-60px); */
}
.DropCap {
  font-size: 3.33em;
  line-height: 0.92;
  color: #5747e4;
  font-family: inherit;
  display: inline-block;
  width: auto;
  height: auto;
  text-transform: uppercase;
}
.BlogPage-img {
  width: 350px;
  height: 400px;
  border-radius: 1rem;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}
.QouteAuthor {
  color: #3b3663;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
}
.commentBlock-bg {
  background-color: #f5f6f7;
  border-radius: 0.5rem;
}
.commentBlock-bg::after {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3b3663;
}
.commentBlock-img {
  width: 130px;
  vertical-align: middle;
  border-radius: 10rem;
}
.commentsection-img {
  width: 85px;
  vertical-align: middle;
  border-radius: 10rem;
}
.ChatImg {
  color: #2e3192;
}
.ChatImg:hover {
  color: #f07379;
}
.PaginationLink {
  color: #3b3663;
  font-weight: 800;
  font-size: 1rem;
  text-decoration: none;
}
.PaginationLink:hover {
  color: #3239ee;
  text-decoration: none;
}
.Seperator {
  border-right: 2px solid #3b366346;
  padding-right: 3rem;
}
.Seperator2 {
  padding-left: 3rem;
}

.CaseStudyCard {
  border-radius: 5px;
  background-color: #fff;
}

.Blog-Text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #6d7c90;
  transform: translateY(10px);
}

.Blog1-bg {
  background-image: url("./assets/About_bg.jpg");
  background-repeat: no-repeat;
}
.blog1-btn {
  background: white !important;
  border-color: black !important;
  color: black !important;
  border-radius: 0;
  border: 2px solid;
  box-shadow: 3px 3px black;
  font-size: 18px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 60% !important;
  line-height: 1.3333333;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
}
.blog1-btn :focus {
  outline: none;
  border: 0px;
}
.blog1-btn:hover {
  box-shadow: 0px 0px black;
  transition: ease-in-out 0.2s;
}
.BlogImg {
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0px rgba(163, 163, 163, 1);
}

.Blog-Card {
  box-shadow: 0px 2px 70px 0px rgba(110, 130, 208, 0.18);
}

.gta-Container {
  background-color: #2e3192;
}

.gta-Header {
  font-size: 3rem;
  color: #ffffff;
  font-weight: 700;
}
.gta-Text {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: normal;
}
.cta_fifthText {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.ChooseImg {
  width: 450px;
  height: 300px;
}
.ChooseImg2 {
  width: 414px;
  height: 301px;
}
.AvailableCard {
  box-shadow: 0 2px 22px 8px rgba(56, 125, 255, 0.1);
  padding: 57px 24px 30px;
  position: relative;
  border-radius: 10px;
  /* height: 550px; */
}
.CaseStudyLogo {
  max-width: 100%;
  height: auto;
}
.mw {
  width: auto;
}
.FooterColor2 {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  line-height: 2rem;
}
.FooterColor2:hover {
  text-decoration: none;
  color: #fff;
}

.footerbtn {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f1f1f1;
  border-radius: 1rem;
  box-shadow: 0 5px 15px -5px #00000070;
  color: #3498db;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.footerbtn img {
  line-height: 90px;
  font-size: 26px;
  transition: 0.2s linear;
  padding-top: 0.8rem;
}
.footerbtn:hover img {
  transform: scale(1.1);
  filter: brightness(0) invert(1);
}
.footerbtn::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: #2e3192;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.footerbtn:hover::before {
  animation: flash 0.7s 1;
  top: -10%;
  left: -10%;
}
@keyframes flash {
  0% {
    left: -100%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    left: -10%;
    top: -10%;
  }
}
.text-secondary-center {
  font-size: 1rem;
}
.pTag {
  font-size: 1rem;
}
.blueColor {
  color: #2e3192;
}
.contactSize {
  font-size: 56px;
  font-weight: 900;
  color: #2e3192;
}
.contactInfo {
  font-size: 26px;
  font-weight: 700;
  color: #2e3192 !important;
  text-decoration: none;
}
.contactInfo:hover {
  text-decoration: none;
}
.contactAdress {
  text-align: center;
  margin-top: -40px;
  margin-left: 15px;
  color: #2e3192;
  background-color: #fbfbfe;
  width: 8rem;
}
.contactAdressCard {
  border: 3px solid #2e3192;
  background-color: #fbfbfe;
}
.contactAdressInfo {
  color: black !important;
}

.banner-margin-top {
  margin-top: 8rem;
}

.thankyou-message {
  position: fixed;
  top: 10%;
  right: 1%;
  background-color: #98fb98;
  border-radius: 10px;
  display: none;
  z-index: 9999;
}
.background-2 {
  height: 349px;
  padding: 131px 0px 165px 0px;
  background-image: linear-gradient(to top, #134596, #2e338a),
    linear-gradient(to top, #9b43d9, #4d0083);
}
.background-3 {
  background-color: #dde6f0;
}
.rectangle-1 {
  padding: 24px 14px;
  border-radius: 17px;
  box-shadow: -10px 11.1px 27px 0 rgba(197, 215, 236, 0.46);
  background-color: #e6edf4;
}
@media (max-width: 767.98px) {
  .sizeFont {
    font-size: 1rem !important;
  }
}
.testi-margin-row {
  margin-top: 5rem !important;
}
@media (max-width: 767.98px) {
  .testi-margin-row {
    margin-top: 1rem !important;
  }
  .testi-margin-row2 {
    margin-top: 2rem !important;
  }
}
.growUp {
  color: #ff904b;
}

/********** 12/28/2021 **************/

.stats-Text {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 400;
}
.small-heading {
  font-size: 1.7rem;
  color: #29409a;
  font-weight: 600;
}
.clientcard {
  border-radius: 8px;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
  border: none !important;
  padding: 15px;
  background-color: #f9f9f9;
}
.linkText {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.linkText:hover {
  text-decoration: none;
  outline: none;
  color: #fff;
}
.pp-text {
  font-size: 1.2rem;
  color: #666666;
}
.pp-text:hover {
  color: #666666;
}
.privacyCard {
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0,0,0,.125); */
  border-radius: 0.25rem;
  box-shadow: 0 8px 60px 0 rgba(103, 151, 255, 0.11),
    0 12px 90px 0 rgba(103, 151, 255, 0.11);
}
.privacy-Subheading {
  color: #2e3192;
  font-weight: 900;
  font-size: 2.5rem;
}
.PrivacyHeading {
  color: #2e3192;
  font-weight: 900;
  font-size: 3rem;
}
.bulliten ul li {
  list-style-type: square;
}
.BlogPage-Title-1 {
  font-size: 1.2rem;
  color: #3b3663;
  font-weight: 800;
}
.read-more-button {
  font-weight: 600;
  cursor: pointer;
}
.thankyouCard {
  background-color: #fff;
  border-radius: 1rem;
  /* box-shadow: 15px 5px 100px 0px #2e319270; */
  animation: float 6s ease-in-out infinite;
}
.thankyouHead {
  font-family: "Engagement", cursive !important;
  font-weight: 800;
  color: #2e3192;
}
@keyframes float {
  0% {
    box-shadow: 15px 5px 100px 0px #2e319270;
    transform: translatey(0px);
  }
  50% {
    box-shadow: 15px 0px 100px 0px #2e319270;
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 15px 5px 100px 0px #2e319270;
    transform: translatey(0px);
  }
}

p {
  color: #666;
}

.Rounded-Rectangle-4 {
  width: 271px;
  height: 57px;
  border: 1px solid #a6a6a6;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 0 6.7px 1.3px rgb(0 0 0 / 35%);
  background-color: #fff;
}
.Rounded-Rectangle-4:hover {
  transition: 0.2s ease-out;
  box-shadow: none;
}

.react-tel-input .form-control{
  width: 20rem;
}

