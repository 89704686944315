/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1381px){
  .email-field{
    width: 100%;
  }
  #submit-btn{
    position: absolute;
    left: 85%;
  }
  /* .image-container::after {
    content: "";
    width: 62.5% !important;
    height: 93.5%;
    position: absolute;
    top: 0;
    left: 19% !important;
  } */
}
@media (min-width: 1281px) and (max-width: 1380px) {
  /* footer */

  #email-submit {
    width: 13rem;
  }
  #name-submit {
    width: 12rem;
  }
  .heading-text #heading-main {
    font-weight: 900;
    font-size: 4rem;
    color: white;
  }
  .email-field{
    width: 100%;
  }
  #submit-btn{
    position: absolute;
    left: 78%;
  }
  /* .image-container::after {
    content: "";
    width: 62.3% !important;
    height: 93.5%;
    position: absolute;
    top: 0;
    left: 18.9% !important;
  } */
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
*/
/* @media (min-width: 1025px) and (max-width: 1280px){
  .email-field{
    width: 100%;
  }
  #submit-btn{
    position: absolute;
    left: 78%;
  }
} */
@media (min-width: 1025px) and (max-width: 1200px) {
  .rectangle-1 > img {
    width: 90px;
  }
  /* home page */

  /* .circle {
    top: -5%;
    right: -2%;
    opacity: 0.7;
  } */
  /* .image-container::after {
    width: 74.3% !important;
    height: 93.5%;
    left: 13% !important;
  } */
  .heading-text #heading-main {
    font-weight: 900;
    font-size: 4rem;
    color: white;
  }
  /* .email-field{
    width: 84%;
  } */
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* contactpage */

  .bg-testi img {
    position: absolute;
    z-index: -1;
    /* bottom: -2rem; */
    transform: translateX(530px) translateY(-341px);
    /* right: 1pc; */
    width: 350px;
}
.bg-testi2 img {
  position: absolute;
  z-index: -1;
  transform: translateX(559px) translateY(-440px);
  /* top: -1.5rem; */
  /* right: 30%; */
  width: 35px;
}
  .contact-header1 {
    font-size: 1.2rem;
  }
  .contact-header2 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-top: 1rem;
  }
  .header-description {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  /* footer */

  #email-submit {
    width: 11rem;
  }
  #name-submit {
    width: 12rem;
  }
  .email-field{
    width: 100%;
  }
  #submit-btn{
    position: absolute;
    left: 78%;
  }

  /* blogpage1/2 */

  .BlogPage-Header {
    font-size: 3rem;
  }
  /* .BlogPage-Title {
    font-size: 1.5rem;
  } */

  /* contactForm */

  .inputBox {
    width: 26rem;
  }
  #Text-Area {
    width: 54.2rem;
  }
  .heading-text #heading-main {
    font-weight: 900;
    font-size: 4rem;
    color: white;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .bg-testi img {
    position: absolute;
    z-index: -1;
    /* bottom: -2rem; */
    transform: translateX(380px) translateY(-425px);
    /* right: 1pc; */
    width: 350px;
}
.bg-testi2 img {
  position: absolute;
  z-index: -1;
  transform: translateX(429px) translateY(-460px);
  /* top: -1.5rem; */
  /* right: 30%; */
  width: 35px

}
.circle img {
  position: absolute;
  /* top: -9%; */
  /* right: 13%; */
  transform: translateX(195px) translateY(-430px);
  z-index: -1;
  opacity: 0.1;
}
  .testi-card{
    margin-top: 15px;
  }
  /* .testimonial{
    width: 100px;
  } */
  .rectangle-1 > img {
    width: 50px;
  }
  /* .AvailableCard {
    height: 530px;
  } */
  /* Homepage */

  .bnr {
    height: 480px;
  }
  .main-video {
    height: 174px;
    width: 270px;
  }
  #diviCover #dC {
    max-width: 732px;
    width: 410px;
    border-radius: 10px;
    z-index: 233;
    background: linear-gradient(120deg, #2e3390, #513d8d);
    margin-top: 25px;
    height: 323px;
  }
  /* .image-container::after {
    width: 59.2% !important;
    height: 93.5%;
    left: 20.5% !important;
  } */
  /* .circle {
    top: -6%;
    right: 0%;
    opacity: 0.7;
  } */
  /* .pentagon {
    top: -9%;
    left: -50%;
  } */
  .play-btn {
    top: 50%;
    right: 26%;
  }

  .test-quotes {
    width: 65px;
    height: 52px;
    top: -16px;
  }
  .lst {
    width: 90px;
  }
  .rst {
    bottom: -1px;
    width: 124px;
  }
  .rst2 {
    top: -3px;
    width: 32%;
  }
  .test-quotes2 {
    width: 65px;
  }
  .lst2 {
    width: 100px;
  }
  .blueC {
    width: 35px;
    bottom: -2rem;
  }
  .blueC2 {
    width: 35px;
    bottom: -2rem;
  }

  .heading-text #heading-main {
    font-weight: 900;
    font-size: 2rem !important;
    color: white;
  }
  .Blog-Text {
    font-size: 0.8rem;
  }

  #heading-main {
    font-size: 2.5rem !important;
  }

  .heading-text p {
    font-size: 1rem;
  }
  #sub-text {
    font-size: 0.9rem;
  }
  .rf {
    font-size: 15px;
  }
  .services-heading {
    font-size: 1.5rem;
  }
  .heading-page {
    font-size: 3rem;
  }
  .services-subheading {
    font-size: 1.4rem;
  }

  /* contactpage */

  .contact-header1 {
    font-size: 1rem;
  }
  .contact-header2 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .header-description {
    font-size: 1rem;
    margin-top: 1rem;
  }
  /* webdev page */

  .Webdev-Text {
    font-size: 1.5rem;
    font-weight: 400;
  }
  /* footer */

  .email-panel {
    border-radius: 0.5rem;
  }
  .email-field {
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
  #submit-btn {
    margin-left: -0.2rem;
    width: 9rem;
  }
  #email-submit {
    width: 17rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #name-submit {
    width: 17rem;
  }

  /* CTa-fifth */

  .gta-Header {
    font-size: 2.5rem;
  }
  .gta-Text {
    font-size: 1.2rem;
  }

  /* contactPage */

  .Contact-icon {
    width: 50px;
  }
  .Cards h5 {
    font-size: 1.2rem;
  }
  .contactCard h2 {
    font-size: 2.5rem;
  }

  /* blog Page */

  .Blog-Header {
    font-size: 3rem;
  }
  .Blog-Title {
    font-size: 1.5rem;
  }

  /* blogpage1/2 */

  .BlogPage-Header {
    font-size: 2.5rem;
  }
  /* .BlogPage-Title {
    font-size: 1.5rem;
  } */

  /* contactForm */

  .inputBox {
    width: 20rem;
  }
  #Text-Area {
    width: 42rem;
  }
  /* videoproductionpage */

  .play-btn-video-inside {
    width: 80px;
    height: 80px;
  }
  .play-btn-video {
    width: 140px;
    height: 140px;
    left: 50%;
    top: -1rem;
  }
  .videoSize {
    width: 450px;
  }

  /* SEO page */

  .seo-banner {
    height: max-content;
  }
  #seo-form input {
    width: 100%;
  }
  #seo-pentagon img {
    width: 250px;
    bottom: 3rem;
    left: -4rem;
    position: relative;
  }
  #seo-cta-pink {
    width: 210px;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* Only for work section */

@media (min-width: 576px) and (max-width: 767px) {
  #diviCover #dC {
    width: 89%;
    /* width: 430px; */
    height: 260px;
  }
  /* .image-container::after {
    width: 84.1% !important;
    height: 92%;
    left: 8% !important;
  } */
  .play-btn {
    height: 135px;
    width: 135px;
    top: 52%;
    right: 22%;
  }
  .play-btn-inside {
    width: 80px;
    height: 80px;
  }
  .play {
    width: 1rem;
  }
  /* .circle {
    top: -11%;
    right: -7%;
    opacity: 0.7;
  } */
  /* .pentagon {
    top: -16%;
    left: -78%;
  } */
  .email-field{
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  #diviCover #dC {
    width: 63%;
    height: 215px;
  }
  /* .image-container::after {
    width: 58.5%;
    left: 21%;
  } */
  .play-btn {
    height: 135px;
    width: 135px;
    top: 55%;
    right: 24%;
  }
  .play-btn-inside {
    width: 80px;
    height: 80px;
  }
  .play {
    width: 1rem;
  }
  /* .circle {
    top: -13%;
    right: 4%;
    opacity: 0.7;
  } */
  /* .pentagon {
    top: -35%;
    left: -67%;
  } */
}
/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .bg-testi img {
    position: absolute;
    z-index: -1;
    transform: translateX(301px) translateY(-286px);
    width: 251px;
}
.bg-testi2 img {
  position: absolute;
  z-index: -1;
  transform: translateX(287px) translateY(-325px);
  width: 35px;
}
.circle img {
  position: absolute;
  /* top: -9%; */
  /* right: 13%; */
  transform: translateX(121px) translateY(-310px);
  z-index: -1;
  opacity: 0.3;
}
  .mobile-map{
    width:auto;
  }
  .rectangle-1 > img {
    width: 80px;
  }
  .rf-descr.rf-18 {
    font-size: 14px;
  }
  .rf-steps .rf-num.rf-right {
    right: -15px;
  }
  .rf-steps .rf-num {
    font-weight: 600;
    font-size: 75px;
    color: #fff;
    opacity: 0.2;
    position: absolute;
    top: -35px;
  }
  /* .AvailableCard {
    height: 390px;
  } */
  .text-white {
    font-size: 1.5rem;
  }
  .Seperator {
    border-right: 0px solid #3b366346;
    padding-right: 0rem;
  }
  .Seperator2 {
    padding-left: 0rem;
  }
  /* menu */

  /* .menu #logo {
    margin-left: 0rem;
  } */

  #logo {
    width: 60px;
  }
  .openbtn {
    margin-right: -1pc;
  }
  .sidebar a {
    font-size: 1.2rem;
  }

  .sidebar .closebtn {
    left: 0px;
  }
  .dropdown-container a {
    font-size: 1rem;
  }
  /* Homepage */
  .main-video {
    width: 350px;
    margin-left: 0rem;
  }
  .bnr {
    height: 65rem;
  }
  .stats-Text {
    font-size: 1.2rem;
  }
  .contact-header1 {
    font-size: 1rem;
  }
  .contact-header2 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .header-description {
    font-size: 1rem;
    margin-top: 1rem;
  }

  /* footer */
  .footerHeader h5 {
    font-size: 1rem;
  }
  .footerHeader h6 {
    font-size: 0.8rem;
  }
  .email-panel {
    border-radius: 0.5rem;
  }
  .email-field {
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
  #submit-btn {
    margin-left: 0.7rem;
    width: 9rem;
    margin-bottom: 0.5rem;
  }
  #email-submit {
    width: 18rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #name-submit {
    margin-top: 0.5rem;
    width: 18rem;
  }
  .footer-Mainlogo {
    width: 80px;
  }
  .footerText {
    font-size: 0.9rem;
  }

  /* CTa-fifth */

  .gta-Header {
    font-size: 2.2rem;
  }
  .gta-Text {
    font-size: 1rem;
  }

  /* contactPage */

  .Contact-icon {
    width: 50px;
  }
  .Cards h5 {
    font-size: 1.2rem;
  }
  .contactCard h2 {
    font-size: 2.5rem;
  }

  /* About Page  */

  .about-heading {
    font-size: 3rem;
  }
  .about-Text {
    font-size: 1rem;
  }
  .Founder-Header {
    font-size: 3rem;
  }
  .Founder-Text {
    font-size: 1.2rem;
  }
  .Founder-Description {
    font-size: 1rem;
  }

  /* blog Page */

  .Blog-Header {
    font-size: 1.7rem;
  }
  .Blog-Title {
    font-size: 1.2rem;
  }
  .BlogImg {
    width: 350px;
  }

  /* blogpage1/2 */

  .BlogPage-Header {
    font-size: 2rem;
  }
  /* .BlogPage-Title {
    font-size: 1.1rem;
    line-height: 1.7rem;
  } */
  .BlogPage-Text {
    font-size: 1rem;
  }

  /* contactForm */

  .inputBox {
    width: 25rem;
  }
  #Text-Area {
    width: 25rem;
  }
  .btn-div {
    padding-left: 1.5rem;
  }

  /* videoproduction page */

  .ChooseImg {
    width: 350px;
    height: 250px;
  }
  .ChooseImg2 {
    width: 338px;
    height: 246px;
  }
  .cta-image-right img {
    width: 350px;
    height: 250px;
  }
  .play-btn-video-inside {
    width: 80px;
    height: 80px;
  }
  .play-btn-video {
    width: 140px;
    height: 140px;
    left: 50%;
    top: 0rem;
  }
  .bnr-video {
    height: 40rem;
  }
  .heading-text #heading-main {
    font-weight: 900;
    font-size: 3rem;
    color: white;
  }
  /* webdev */
  .card2 {
    margin-top: 8rem;
  }
  .Webdev-Text {
    font-size: 1.2rem;
  }
  .heading-page {
    font-size: 3rem !important;
  }
  .videoSize {
    width: 350px;
  }

  /* Seo page */

  /* .card-seo {
    width: 250px;
    max-width: 228px;
  } */

  .seo-banner {
    height: max-content;
  }
  #seo-pentagon img {
    width: 250px;
    bottom: 3rem;
    left: -4rem;
    position: relative;
  }
  #seo-cta-pink {
    width: 126px;
  }
  .pp-text{
    font-size: 1rem;
  }
  .privacy-Subheading{
    font-weight: 600;
    font-size: 1.5rem;
  }
  .PrivacyHeading{
    font-weight: 600;
    font-size: 1.8rem;
  }
}
@media (min-width: 596px) and (max-width: 785px) {
  #seo-form input {
    width: 100%;
  }
  .pd{
    display: none;
  }
  .display-4,.display-2,.display-3{
    font-size: 2.5rem!important;
  }
  .list-disc{
    list-style: none;
  }
  .mt-48{
    margin-top: 0;
  }
  .gap-20{
    gap: 0;
  }
}
@media (min-width: 481px) {
  .clientImage1 {
    display: none;
  }
}
@media (max-width: 480px) {
  .clientImage2 {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 595px) {
  #seo-form input {
    width: 100%;
  }
  .pd{
    display: none;
  }
  .display-4,.display-2,.display-3{
    font-size: 2.5rem!important;
  }
  .list-disc{
    list-style: none;
  }
  .mt-48{
    margin-top: 0;
  }
  .gap-20{
    gap: 0;
  }
  
}
@media (min-width: 376px) and (max-width: 425px) {
  #seo-form input {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  #seo-form input {
    width: 100%;
  }
  
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .pd{
    display: none;
  }
  .display-4,.display-2,.display-3{
    font-size: 2.5rem!important;
  }
  .list-disc{
    list-style: none;
  }
  .mt-48{
    margin-top: 0;
  }
  .gap-20{
    gap: 0;
  }
  .Rounded-Rectangle-4 {
    width: 65%;
    height: 14%;
    font-size: 0.9rem;
  }
  .rectangle-1 > img {
    width: 60px;
  }
  .mobile-map{
    width:auto;
  }
  /* .rf-descr.rf-18{
    font-size: 14px;
  }
.rf-steps .rf-num.rf-right {
    right: -15px;
}
.rf-steps .rf-num {
    font-weight: 600;
    font-size: 75px;
    color: #FFF;
    opacity: .2;
    position: absolute;
    top: -35px;
} */
  .rf-steps {
    display: none;
  }
  /* .AvailableCard {
    height: 350px;
  } */
  .text-white {
    font-size: 1rem;
  }
  .pop-btn {
    font-size: 0.9rem;
    width: 8rem;
  }
  .Seperator {
    border-right: 0px solid #3b366346;
    padding-right: 0rem;
  }
  .Seperator2 {
    padding-left: 0rem;
  }
  .stats-video {
    width: 270px;
  }
  /* Seo page */

  .card-seo {
    /* width: 250px;
    max-width: 228px; */
    height: 200px;
  }
  .seoCard b {
    font-size: 1.2rem !important;
  }

  .videoSize {
    width: 250px;
  }

  /* menu */

  /* .menu #logo {
    margin-left: 0rem;
  } */

  #logo {
    width: 55px;
  }
  #whatsapp-icon{
    width: 100px !important;
  }
  .openbtn {
    margin-right: -1pc;
  }

  .sidebar a {
    font-size: 1.2rem;
  }

  .sidebar .closebtn {
    left: 0px;
  }
  .dropdown-container a {
    font-size: 0.9rem;
  }
  /* Homepage */

  .main-video {
    width: 260px;
    height: 165px;
    margin-left: 0rem;
    margin-top: -5rem;
    margin-bottom: 1rem;
  }
  .bnr {
    height: 50rem;
  }
  .heading-main {
    font-size: 3rem;
  }

  #diviCover #dC {
    max-width: 100%;
    height: auto;
  }
  /* .image-container::after {
    width: 69.8% !important;
    height: 85% !important;
    left: 15% !important;
  } */
  .play-btn {
    width: 77px;
    height: 77px;
    /* top: 57%; */
    left: 50%;
  }
  .play-btn-inside {
    width: 48px;
    height: 48px;
  }
  .play-btn-video {
    width: 110px;
    height: 110px;
    left: 50%;
    top: 0rem;
  }
  .play-btn-video-inside {
    width: 65px;
    height: 65px;
  }
  .play {
    width: 1rem;
  }
  /* .circle {
    top: -6%;
    right: -5%;
    opacity: 0.4;
  } */
  /* .pentagon {
    top: -16%;
    left: -143%;
  } */
  .stats-Text {
    font-size: 1rem;
  }
  /* .testimonial {
    margin-top: -125px;
  } */
  .heading-page {
    color: #2e3192;
    font-weight: 900;
    font-size: 2rem;
  }
  /* .cf-fields {
    width: 15rem;
  } */
  .heading-text{
    font-size: 2rem;
  } 
  #heading-main {
    font-size: 2rem !important;
  }
  .heading-text p {
    font-size: 1rem;
  }
  .test-quotes {
    width: 65px;
    height: 52px;
    top: -16px;
  }
  .lst {
    width: 90px;
  }
  .rst {
    bottom: -1px;
    width: 124px;
  }
  .rst2 {
    top: -3px;
    width: 32%;
  }
  .test-quotes2 {
    width: 65px;
  }
  .lst2 {
    width: 100px;
  }
  .blueC {
    width: 35px;
    bottom: -2rem;
  }
  .blueC2 {
    width: 35px;
    bottom: -2rem;
  }
  /* contactpage */

  .contact-header1 {
    font-size: 0.8rem;
  }
  .contact-header2 {
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .header-description {
    font-size: 0.8rem;
    margin-top: 0.8rem;
  }

  /* footer */

  .footerHeader h5 {
    font-size: 0.9rem;
  }
  .footerHeader h6 {
    font-size: 0.8rem;
  }
  .email-panel {
    border-radius: 0.5rem;
  }
  .email-field {
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
  #submit-btn {
    margin-left: 0.2rem;
    width: 7rem;
    margin-bottom: 0.5rem;
  }
  .services {
    font-size: 13px;
    padding: 5px 10px 5px 10px;
}
  #email-submit {
    width: 10rem !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #name-submit {
    margin-top: 0.5rem;
    width: 10rem;
  }
  .footer-Mainlogo {
    width: 80px;
  }
  .footerText {
    font-size: 0.9rem;
  }
  .footer-menu-heading {
    font-size: 1.5rem;
    line-height: 1rem;
  }
  .footer-menu-heading img {
    width: 40px;
  }

  /* CTa-fifth */

  .gta-Header {
    font-size: 2.2rem;
  }
  .gta-Text {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  /* contactPage */

  .Contact-icon {
    width: 50px;
  }
  .Cards h5 {
    font-size: 1.2rem;
  }
  .contactCard h2 {
    font-size: 2rem;
  }
  .contactCard p {
    font-size: 1rem;
  }

  /* About Page  */

  .about-heading {
    font-size: 2rem;
  }
  .about-Text {
    font-size: 0.9rem;
  }
  .Founder-Header {
    font-size: 2rem;
  }
  .Founder-Text {
    font-size: 1rem;
  }
  .Founder-Description {
    font-size: 1rem;
  }

  /* blog Page */

  .Blog-Header {
    font-size: 1.5rem;
  }
  .Blog-Title {
    font-size: 1rem;
  }
  .Blog-Text {
    font-size: 0.8rem;
  }
  .blog1-btn {
    font-size: 0.7rem;
    width: 90px;
  }

  /* blogpage1/2 */

  .BlogPage-Header {
    font-size: 1.5rem;
  }
  /* .BlogPage-Title {
    font-size: 1rem;
    line-height: 1.5rem;
  } */
  .BlogPage-Text {
    font-size: 0.8rem;
  }

  /* contactForm */

  .inputBox {
    width: 15.5rem;
  }
  #Text-Area {
    width: 15.5rem;
  }
  .btn-div {
    padding-left: 1rem;
  }
  /* testiimonials */

  /* .testi-card {
    height: 410px;
  } */

  /* videoproduction page */

  .ChooseImg {
    width: 200px;
    height: 170px;
  }
  .ChooseImg2 {
    width: 270px;
    height: 196px;
  }

  .cta-image-right img {
    width: 270px;
    height: 200px;
  }
  .Webdev-Text {
    font-size: 1.2rem;
  }

  /* webdev */

  .card2 {
    margin-top: 8rem;
  }
  .Webdev-Text {
    font-size: 1.2rem;
  }
  .heading-page {
    font-size: 2rem !important;
  }

  /* seo Page */
  .seo-banner {
    height: max-content;
  }
  #seo-pentagon img {
    width: 250px;
    bottom: 9rem;
    left: -9rem;
    position: relative;
  }
  #seo-form h2 {
    font-size: 1.2rem;
  }
  #seo-form {
    padding: 2rem 1rem 1rem 1rem;
  }
  #seo-pink img {
    width: 110px;
  }
  #seo-cta-pink {
    width: 110px;
  }
  .BlogImg {
    width: 250px;
  }

  .pp-text{
    font-size: .8rem;
  }
  .privacy-Subheading{
    font-weight: 600;
    font-size: 1.5rem;
  }
  .PrivacyHeading{
    font-weight: 600;
    font-size: 1.6rem;
  }
  .bg-testi img {
    position: absolute;
    z-index: -1;
    /* bottom: -2rem; */
    transform: translateX(152px) translateY(-270px);
    /* right: 1pc; */
    width: 130px;
  }
.bg-testi2 img {
  position: absolute;
  z-index: -1;
  transform: translateX(135px) translateY(-315px);
  /* top: -1.5rem; */
  /* right: 30%; */
  width: 25px;
}
.pentagon img{
  display: none;
}
  .circle img {
    position: absolute;
    /* top: -9%; */
    /* right: 13%; */
    transform: translateX(50px) translateY(-300px);
    z-index: -1;
    opacity: 0.1;
    width: 100px;
  }
}
@media (min-width: 899px) {
  #menu-icon {
    display: none;
  }
  .contactInfoMargin {
    margin-left: -17px;
  }
}
@media (max-width: 900px) {
  .menu.navbar .nav {
    display: none;
  }
}
/* @media (min-width: 1024px) and (max-width: 1350px) {
  .AvailableCard {
    height: 775px;
  }
} */
